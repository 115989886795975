import Form from '../components/Form';
import ParrallaxBanner from '../components/ParrallaxBanner';
import BannerImage from "../assets/images/Contact/banner2.jpg";
import { Helmet } from 'react-helmet';
import QuickNav from "../components/QuickNav";
let BannerData = {
    backgroundImage : BannerImage,
    heading : "Rent a Luxury Apartment in Flatbush",
    subHeading : "",
    description: `For more information on luxury rentals in Flatbush, Brooklyn or to schedule a tour of the Flatbush apartments at 2339 Nostrand Ave, add your details to the form below and click “submit” to learn more.`
}
const NavData = {
  prevlink : {
      text : "Neighborhood" ,   
      prev : "/neighborhood#root",
  },
  nextlink : {
      text : "Home" ,   
      next : "/#root",
  }
}

const Contact = () => {
    return ( 
        <>
          <Helmet>
            <title>Contact Us | Luxury Flatbush Apartments For Rent</title>
            <meta name="description" content="Apply for Flatbush apartments. Contact us to schedule a tour of Flatbush apartments or learn more about the Flatbush apartments for rent at 2339 Nostrand Ave." />
          </Helmet>
          <ParrallaxBanner data={BannerData} />
          <Form />
          <QuickNav data={NavData}/>  
        </>

     );
}
 
export default Contact;
