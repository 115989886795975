import Banner from "../components/Banner";
import BannerImage from "../assets/images/Amenities/banner5.jpg";
import TwoColumn from "../components/TwoColumn";
import QuickNav from "../components/QuickNav";
//2 col images

import twoColImage1 from "../assets/images/Amenities/Group 3.png";
import twoColImage2 from "../assets/images/Amenities/Group 4.png";
import twoColImage3 from "../assets/images/Amenities/Group 5.png";
import twoColImage4 from "../assets/images/Amenities/Group 6.png";
import twoColImage5 from "../assets/images/Amenities/Group 7.png";
import twoColImage6 from "../assets/images/Amenities/Group 8.png";
import twoColImage7 from "../assets/images/Amenities/Group 9.png";
import twoColImage8 from "../assets/images/Amenities//Layer_20_new.png";
//import twoColImage9 from "../assets/images/Amenities//Layer 30.png";
import { Helmet } from "react-helmet";
const NavData = {
    prevlink : {
        text : "Residences" ,   
        prev : "/residences#root",
    },
    nextlink : {
        text : "Availabilities" ,   
        next : "/availabilities#root",
    }
}
let BannerData = {
    backgroundImage: BannerImage,
    heading: "Brooklyn Living, Upgraded",
    subHeading: "",
    description: "Living at 2339 Nostrand means you can take advantage of the building’s array of impressive amenities. Along with modern apartments in Flatbush, You’ll enjoy stylish common areas, a state-of-the-art fitness center, and a rooftop terrace, all just steps from your front door."
}
let TwoColumnData = [
    {
        variant: "blue",
        heading: "Lobby",
        subheading: "",
        description: "Every time you enter, you'll be greeted by the building's chic lobby, filled with contemporary design elements and modern furnishings.",
        image: twoColImage8,
        imageDesc: 'A stylish and modern lobby area with comfortable seating, elegant lighting, and large windows allowing natural light to fill the space.',
        flexDirection: "row",
    },
    {
        variant: "white",
        heading: "Rooftop Terrace",
        subheading: "",
        description: "If you’re looking for an apartment with rooftop access in Flatbush, you’ll find it at 2339 Nostrand Ave. On warm summer nights, head to the Rooftop Terrace to lounge, entertain, or mingle with your neighbors. ",
        image: twoColImage7,
        imageDesc: 'A spacious rooftop terrace with seating and tables, offering a panoramic view of the city skyline under a clear blue sky.',
        flexDirection: "row-reverse",
    },
    {
        variant: "blue",
        heading: "Co-Working Space",
        subheading: "",
        description: "Anyone with a modern or hybrid work schedule will appreciate the on-site Co-Working Space, which allows you to separate where you work from where you live.",
        image: twoColImage5,
        imageDesc: 'A collaborative workspace featuring large tables, comfortable chairs, and natural lighting, designed for productivity and community.',
        flexDirection: "row",
    },
    {
        variant: "white",
        heading: "Zen Garden",
        subheading: "",
        description: "Nothing is better than apartments with shared outdoor space in Brooklyn. A designated outdoor Zen Garden offers plenty of room to breathe when you want to step away.",
        image: twoColImage6,
        imageDesc: 'A peaceful garden space with lush greenery, cozy outdoor seating, and a tranquil atmosphere for relaxation.',
        flexDirection: "row-reverse",
    },
    {
        variant: "blue",
        heading: "Fitness Center",
        subheading: "",
        description: "Step up your fitness without an expensive gym membership by using the building's state-of-the-art fitness center, equipped with modern cardio machines, free weights, and more.",
        image: twoColImage1,
        imageDesc: 'A well-equipped fitness center with a variety of exercise machines and weights, designed for residents’ health and wellness.',
        flexDirection: "row",
    },
    {
        variant: "white",
        heading: "Covered Parking",
        subheading: "",
        description: "Coming home at the end of a long day just got easier. Covered parking makes street parking a thing of the past.",
        image: twoColImage2,
        imageDesc: 'An indoor parking area with secure, designated parking spaces for residents.',
        flexDirection: "row-reverse",
    },
    {
        variant: "blue",
        heading: "Laundry Room",
        subheading: "",
        description: "On-site laundry facilities make it easy to incorporate laundry into your routine.",
        image: twoColImage4,
        imageDesc: 'A clean and organized laundry room with multiple washers and dryers, providing convenient laundry facilities.',
        flexDirection: "row",
    },
    {
        variant: "white",
        heading: "Bike Storage",
        subheading: "",
        description: "A designated bike storage area allows you to safely secure your bicycle when not in use.",
        image: twoColImage3,
        imageDesc: 'A designated bike storage area with multiple bicycles securely mounted on wall racks above storage cabinets, providing a safe and organized space for residents to store their bikes.',
        flexDirection: "row-reverse",
    },
    // {
    //     variant: "white",
    //     heading: "Tenant Storage",
    //     subheading: "",
    //     description: "Keep your living space organized by taking advantage of the building's convenient tenant storage area.",
    //     image: twoColImage9,
    //     flexDirection: "row",
    // },
]
export default function Home() {
    return (
        <>
            <Helmet>
                <title>Enjoy Upscale Amenities at 2339 Nostrand Avenue in Flatbush</title>
                <meta name="description" content="This luxury Flatbush apartment complex features incredible extras like covered parking, a state-of-the-art fitness center, and a rooftop Zen Garden." />
            </Helmet>
            <Banner data={BannerData} />
            {
                TwoColumnData.map((section, index) => {
                    return <TwoColumn key={index} data={section} />
                })
            }
            <QuickNav data={NavData}/>
        </>
    );
}